import { DEFAULT_COLLABORATIONS_REQUESTS_BODY } from '@/helpers/GeneralHelpers';
import {
  INITIAL_REQUESTS_STATE,
  initRequestsState,
  requestsReducer
} from '@/helpers/RequestsReducer';
import type {
  ICollaborationRequestsContext,
  ICollaborationSelected,
  IRequestBody
} from '@/types/Collaboration';
import { createContext, useMemo, useReducer, useState } from 'react';
import type { ReactNode } from 'react';

const CollaborationRequestsContext =
  createContext<ICollaborationRequestsContext>({
    setCollaborationSelected: () => {},
    collaborationsRequestBody: DEFAULT_COLLABORATIONS_REQUESTS_BODY,
    setCollaborationsRequestBody: () => {},
    requestsState: INITIAL_REQUESTS_STATE,
    dispatchRequests: () => {}
  });

export function CollaborationRequestsContextProvider(props: {
  children: ReactNode;
}) {
  const [collaborationSelected, setCollaborationSelected] =
    useState<ICollaborationSelected>();
  const [requestsState, dispatchRequests] = useReducer(
    requestsReducer,
    INITIAL_REQUESTS_STATE,
    initRequestsState
  );
  const [collaborationsRequestBody, setCollaborationsRequestBody] =
    useState<IRequestBody>(DEFAULT_COLLABORATIONS_REQUESTS_BODY);

  const providerValue = useMemo(
    () => ({
      collaborationSelected,
      collaborationsRequestBody,
      requestsState,
      setCollaborationSelected,
      setCollaborationsRequestBody,
      dispatchRequests
    }),
    [collaborationSelected, collaborationsRequestBody, requestsState]
  );

  return (
    <CollaborationRequestsContext.Provider value={providerValue}>
      {props.children}
    </CollaborationRequestsContext.Provider>
  );
}

export default CollaborationRequestsContext;
