import * as Sentry from '@sentry/nextjs';

interface ISentryOptions {
  data: any;
  message: string;
  tagSection?: string;
}

export const setSentryError = (options: ISentryOptions) => {
  const { data, message, tagSection } = options;
  const sentryError = new Error(message);

  Sentry.addBreadcrumb({
    type: 'debug',
    level: 'info',
    category: 'custom',
    data
  });

  Sentry.captureException(sentryError, {
    tags: {
      section: tagSection
    }
  });
};
