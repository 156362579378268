export enum RequestStatus {
  New = 'NEW',
  Rejected = 'REJECTED',
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Ongoing = 'ONGOING',
  Cancelled = 'CANCELLED',
  Finished = 'FINISHED'
}

export enum Inputs {
  Text = 'text',
  Email = 'email',
  Password = 'password',
  Number = 'number'
}

export enum Endpoints {
  Access = '/access',
  CollaborationRequest = '/collaboration-request',
  User = '/user',
  Influencer = '/influencer',
  FindProducts = '/products/find',
  Dashboard = '/user/dashboard',
  Company = '/company',
  Login = '/login',
  Catalog = '/product/catalog',
  Brand = '/brand',
  MediaProduct = '/media/product',
  UnfinishedRegistration = '/queue/saas/events/saas-unfinished-registration',
  Impersonate = '/impersonate',
  RequestPasswordReset = '/user/request-password-reset',
  SendNewPassword = '/user/reset-password'
}

export enum Routes {
  Login = '/login',
  Register = '/register',
  Panel = '/panel',
  Branding = '/panel/branding',
  Catalog = '/panel/catalog',
  ResetPassword = '/reset-password',
  NewPassword = '/new-password',
  NewPasswordFinal = '/new-password-final'
}

export enum SocialNetworks {
  Instagram = 'INSTAGRAM',
  TikTok = 'TIKTOK',
  Youtube = 'YOUTUBE'
}

export enum CollaborationTypes {
  Lottery = 'Sorteo',
  Story = 'Story',
  Post = 'Post',
  Reels = 'Reels',
  Youtube = 'Youtube',
  Tiktok = 'Tiktok Post'
}

export enum RejectReasons {
  Misalignment = 'Perfil no alineado con la marca',
  InappropriateLanguage = 'Lenguaje no adecuado',
  InappropriateContent = 'Contenido no adecuado',
  FewInteractions = 'Perfil con pocas interacciones',
  FewFollowers = 'Pocos seguidores',
  AcceptInOtherTime = 'Solicitar colaboración en otro momento',
  TooManyForeignerFollowers = 'Perfil con muchos seguidores en otros países',
  CreatorAlreadyAccepted = 'Ya he aceptado una colaboración con este creador'
}

export enum ShippingStatuses {
  Pending = 'SHIPPING_PENDING',
  Shipped = 'SHIPPED',
  Received = 'RECEIVED'
}

export enum CollaborationMetrics {
  Plays = 'plays',
  Shared = 'shared',
  Reach = 'reach',
  Engagement = 'engagement',
  Impressions = 'impression',
  Likes = 'likes',
  Comments = 'comments',
  TimesSaved = 'saved',
  Answers = 'answers',
  TapsExit = 'tapsExit',
  TapsForward = 'tapsForward',
  TapsBack = 'tapsBack'
}

export enum StorageKeys {
  ShowHelpMessage = 'treendShowHelpMessage',
  HelpModalAlreadyShown = 'treendHelpModalAlreadyShown',
  TokenInfo = 'tokenInfo',
  TreendUser = 'treendUser',
  CollaborationSelected = 'collaborationSelected',
  ShowRejectedCollaborations = 'showRejectedCollaborations',
  RequestsFiltersBody = 'requestsFilters',
  CollaborationsFiltersBody = 'collaborationsFilters',
  RequestsState = 'requestsState',
  SuccessOnboardingMessage = 'successOnboardingMessage',
  BrandingData = 'brandingData'
}

export enum MediaTypes {
  FeedImage = 'FEED_IMAGE',
  FeedCarousel = 'FEED_CAROUSEL_ALBUM',
  FeedVideo = 'FEED_VIDEO',
  StoryImage = 'STORY_IMAGE',
  StoryVideo = 'STORY_VIDEO',
  ReelVideo = 'REELS_VIDEO'
}

export enum SelectedCollaborationsActionTypes {
  addId = 'addId',
  deleteId = 'deleteId',
  addArrayOfIds = 'addArrayOfIds',
  deleteArrayOfIds = 'deleteArrayOfIds',
  deleteAll = 'deleteAll'
}

export enum AnimationTypes {
  FromBottomToTop = 'bottomToTop',
  FromRightToLeft = 'rightToLeft'
}

export enum DateFilterOptions {
  Indifferent = 'indifferent',
  Today = 'today',
  Yesterday = 'yesterday',
  ThisWeek = 'thisWeek',
  ThisMonth = 'thisMonth',
  ThisYear = 'thisYear'
}

export enum ButtonVariant {
  primaryBlue = 'primaryBlue',
  primaryWhite = 'primaryWhite',
  secondary = 'secondary',
  success = 'success',
  alert = 'alert',
  custom = 'custom'
}
export enum ButtonSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  custom = 'custom'
}
export enum ButtonType {
  button = 'button',
  submit = 'submit',
  reset = 'reset'
}
export enum ButtonTextStyleClass {
  buttonXs = 'button-xs',
  buttonSm = 'button-s',
  buttonMd = 'button-m',
  buttonLg = 'button-l'
}

export enum RequestActionTypes {
  Restart = 'RESTART',
  SetOffset = 'SET_OFFSET',
  SetRequestBody = 'SET_REQUEST_BODY',
  SetProducts = 'SET_PRODUCTS',
  SetCollaborationsAndProductsIds = 'SET_COLLABORATIONS_AND_PRODUCTS_IDS',
  ShowContent = 'SHOW_CONTENT',
  HideContent = 'HIDE_CONTENT',
  EndProcess = 'END_PROCESS',
  AcceptOrRejectRequest = 'ACCEPT_OR_REJECT_REQUEST',
  UpdateShippingStatus = 'UPDATE_SHIPPING_STATUS',
  RejectInBulk = 'REJECT_IN_BULK'
}

export enum LoaderVariant {
  mainBlue = 'mainBlue',
  whiteBlue = 'whiteBlue',
  success = 'success',
  alert = 'alert'
}

export enum ContentType {
  applicationJson = 'application/json',
  multipartFormData = 'multipart/form-data; charset=utf-8; boundary='
}

export enum AcceptRequestActionTypes {
  setFile = 'setFile',
  setFileError = 'setFileError',
  setFileSuccess = 'setFileSuccess',
  deleteFile = 'deleteFile'
}
export enum DashboardCardTypes {
  Requests = 'requests',
  Collaborations = 'collaborations'
}

export enum DashboardCollaborationCardTypes {
  Ongoing = 'ONGOING',
  Finished = 'FINISHED'
}

export enum LocationTypes {
  Requests = 'requests',
  Collaborations = 'collaborations',
  Dashboard = 'dashboard'
}

export enum Locale {
  English = 'en',
  Spanish = 'es'
}

export enum StorageTypes {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage'
}

export enum RequestErrorCodes {
  InvalidFileTye = 'INVALID_FILE_TYPE',
  FileTooBig = 'FILE_TOO_BIG'
}

export enum Categories {
  Fashion = 'fashion',
  Beauty = 'beauty',
  Home = 'home',
  Food = 'food',
  Fitness = 'fitness',
  Music = 'music',
  Family = 'family',
  Technics = 'technics',
  Animals = 'animals',
  Travel = 'travel',
  Culture = 'culture',
  Healthcare = 'healthcare'
}

export enum Genders {
  Male = 'M',
  Female = 'F',
  Undefined = 'A'
}

export enum BrandingFormActionTypes {
  SetLogo = 'SET_LOGO',
  SetBanner = 'SET_BANNER',
  SetFields = 'SET_FIELDS',
  SetLoading = 'SET_LOADING',
  SetDescription = 'SET_DESCRIPTION',
  CheckFormFilled = 'CHECK_FORM_FILLED',
  SetBriefingPoints = 'SET_BRIEFING_POINTS'
}

export enum BrandApprovalStatuses {
  Pending = 'PENDING',
  Accepted = 'APPROVED',
  Rejected = 'REJECTED'
}

export enum NavbarSections {
  Dashboard = 'dashboard',
  Requests = 'requests',
  Collaborations = 'collaborations',
  Branding = 'branding',
  Catalog = 'catalog',
  Settings = 'settings',
  Billings = 'billings'
}

export enum InfluencerProfileTypes {
  Talent = 'Talent',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
  Blocked = 'Blocked'
}
