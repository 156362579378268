import '../styles/globals.css';

import RouteGuard from '@/components/RouteGuard';
import { BrandContextProvider } from '@/contexts/BrandContext';
import { UserContextProvider } from '@/contexts/UserContext';
import { Quicksand, Roboto } from 'next/font/google';
import * as Sentry from '@sentry/nextjs';
import type { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import nextI18nextConfig from 'next-i18next.config';
import type { AppProps } from 'next/app';
import { type ReactElement, type ReactNode, useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { SWRConfig } from 'swr';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const roboto = Roboto({
  weight: ['100', '300', '400', '500', '700', '900'],
  style: ['normal', 'italic'],
  subsets: ['latin']
});

const quicksand = Quicksand({
  style: ['normal'],
  subsets: ['latin']
});

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    hotjar.initialize(3371836, 6);
  }, []);

  return (
    <SWRConfig
      value={{
        onError: (error) => {
          const globalSWRError = new Error('Global SWR error!');
          globalSWRError.cause = error;
          Sentry.captureException(globalSWRError);
        }
      }}
    >
      <UserContextProvider>
        <RouteGuard>
          <BrandContextProvider>
            {getLayout(
              <>
                <style jsx global>
                  {`
                    :root {
                      --roboto-font: ${roboto.style.fontFamily};
                      --quicksand-font: ${quicksand.style.fontFamily};
                    }
                  `}
                </style>
                <Component {...pageProps} />
              </>
            )}
          </BrandContextProvider>
        </RouteGuard>
      </UserContextProvider>
    </SWRConfig>
  );
}

export default appWithTranslation(MyApp, nextI18nextConfig);
