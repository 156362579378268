import {
  BrandApprovalStatuses,
  Categories,
  CollaborationMetrics,
  CollaborationTypes,
  Genders,
  InfluencerProfileTypes,
  MediaTypes,
  RejectReasons,
  RequestStatus,
  ShippingStatuses,
  SocialNetworks,
  StorageKeys,
  StorageTypes
} from '@/enums/enums';
import type {
  IPublicationMapped,
  IRequestBody,
  ISocialNetworksInfo
} from '@/types/Collaboration';
import type { IOption, IProduct, Variant } from '@/types/Product';
import { cloneDeep } from 'lodash/fp';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import type { Middleware, SWRHook, SWRResponse } from 'swr';
import type { selectedCollaborationsStateValues } from './Reducers';
import { setSentryError } from './SentryFunctions';

export function getStorageValueIntoState(
  storageKey: StorageKeys,
  storageType: StorageTypes
) {
  if (typeof window !== 'undefined' && storageKey in window[storageType]) {
    try {
      return JSON.parse(window[storageType].getItem(storageKey) as string);
    } catch (error) {
      console.error(error);
      setSentryError({
        data: error,
        message:
          `Error on get item from ${storageType}, storageKey: ` + storageKey,
        tagSection: 'getStorageValueIntoState'
      });
      return false;
    }
  }
  return false;
}

export function setStorageValue<T>(
  storageKey: StorageKeys,
  value: T,
  storageType: StorageTypes
) {
  if (typeof window !== 'undefined') {
    try {
      window[storageType].setItem(storageKey, JSON.stringify(value));
    } catch (error) {
      console.error(error);
      setSentryError({
        data: error,
        message:
          `Error on set item in ${storageType}, storageKey: ` + storageKey,
        tagSection: 'setStorageValue'
      });
    }
  }
}

export function getLegibleStatus(status: RequestStatus) {
  const statuses: { [key: string]: string } = {
    [RequestStatus.Accepted]: 'accepted',
    [RequestStatus.Ongoing]: 'ongoing',
    [RequestStatus.Finished]: 'finished',
    [RequestStatus.Cancelled]: 'cancelled',
    [RequestStatus.Rejected]: 'rejected'
  };
  return statuses[status];
}

export function getStatusBackgroundColor(status: RequestStatus) {
  const colors: { [key: string]: string } = {
    [RequestStatus.Accepted]: 'bg-contrast-blue-light',
    [RequestStatus.Ongoing]: 'bg-contrast-pink-light',
    [RequestStatus.Finished]: 'bg-contrast-green-light',
    [RequestStatus.Rejected]: 'bg-contrast-red-light',
    [RequestStatus.Cancelled]: 'bg-contrast-red-light'
  };
  return colors[status];
}

export function getStatusBorderColor(status: RequestStatus) {
  const colors: { [key: string]: string } = {
    [RequestStatus.Accepted]: 'border-contrast-blue-medium',
    [RequestStatus.Ongoing]: 'border-contrast-pink-medium',
    [RequestStatus.Finished]: 'border-contrast-green-medium',
    [RequestStatus.Rejected]: 'border-contrast-red-medium',
    [RequestStatus.Cancelled]: 'border-contrast-red-medium'
  };
  return colors[status];
}

export function getStatusTextColor(status: RequestStatus) {
  const colors: { [key: string]: string } = {
    [RequestStatus.Accepted]: 'text-primary-blue-da',
    [RequestStatus.Ongoing]: 'text-primary-pink',
    [RequestStatus.Finished]: 'text-interaction-green-da',
    [RequestStatus.Rejected]: 'text-contrast-red-medium',
    [RequestStatus.Cancelled]: 'text-contrast-red-medium'
  };
  return colors[status];
}

export function getImageFallbackColor() {
  const colors = [
    'bg-extra-orange',
    'bg-extra-purple',
    'bg-extra-turquoise',
    'bg-extra-ocher'
  ];
  return colors[Math.floor(Math.random() * (3 - 0 + 1) + 0)];
}

export function problemsWithMetrics(collaborationType: string) {
  return (
    collaborationType === CollaborationTypes.Youtube ||
    collaborationType === CollaborationTypes.Tiktok
  );
}

export const REJECT_OPTIONS: [string, string][] = [
  [
    'requestsPage:checkAndAcceptRequestModal.secondStepReject.reasons.misalignment',
    RejectReasons.Misalignment
  ],
  [
    'requestsPage:checkAndAcceptRequestModal.secondStepReject.reasons.inappropriateLanguage',
    RejectReasons.InappropriateLanguage
  ],
  [
    'requestsPage:checkAndAcceptRequestModal.secondStepReject.reasons.inappropriateContent',
    RejectReasons.InappropriateContent
  ],
  [
    'requestsPage:checkAndAcceptRequestModal.secondStepReject.reasons.fewInteractions',
    RejectReasons.FewInteractions
  ],
  [
    'requestsPage:checkAndAcceptRequestModal.secondStepReject.reasons.fewFollowers',
    RejectReasons.FewFollowers
  ],
  [
    'requestsPage:checkAndAcceptRequestModal.secondStepReject.reasons.acceptInOtherTime',
    RejectReasons.AcceptInOtherTime
  ],
  [
    'requestsPage:checkAndAcceptRequestModal.secondStepReject.reasons.tooManyForeignerFollowers',
    RejectReasons.TooManyForeignerFollowers
  ],
  [
    'requestsPage:checkAndAcceptRequestModal.secondStepReject.reasons.creatorAlreadyAccepted',
    RejectReasons.CreatorAlreadyAccepted
  ]
];

export const COLLABORATION_TYPES = {
  [`${CollaborationTypes.Lottery}`]: {
    icon: '/assets/img/24/instagram.svg',
    title: 'Sorteo'
  },
  [`${CollaborationTypes.Reels}`]: {
    icon: '/assets/img/24/instagram.svg',
    title: 'Reels'
  },
  [`${CollaborationTypes.Story}`]: {
    icon: '/assets/img/24/instagram.svg',
    title: 'Stories'
  },
  [`${CollaborationTypes.Youtube}`]: {
    icon: '/assets/img/24/youtube.svg',
    title: 'Youtube'
  },
  [`${CollaborationTypes.Tiktok}`]: {
    icon: '/assets/img/24/tiktok.svg',
    title: 'TikTok'
  },
  [`${CollaborationTypes.Post}`]: {
    icon: '/assets/img/24/instagram.svg',
    title: 'Post'
  }
};

export const SHIPPING_STATUSES = {
  [ShippingStatuses.Pending]: {
    color: 'bg-dark-grey',
    text: 'pending',
    icon: '/assets/img/shipping-pending.svg'
  },
  [ShippingStatuses.Shipped]: {
    color: 'bg-extra-orange',
    text: 'shipped',
    icon: '/assets/img/shipped.svg'
  },
  [ShippingStatuses.Received]: {
    color: 'bg-extra-turquoise',
    text: 'received',
    icon: '/assets/img/received.svg'
  }
};

export const MetricTypes = {
  [CollaborationMetrics.Plays]: {
    typeKey: 'plays',
    icon: '/assets/img/20/play.svg'
  },
  [CollaborationMetrics.Engagement]: {
    typeKey: 'engagement',
    icon: '/assets/img/20/interactions.svg'
  },
  [CollaborationMetrics.Shared]: {
    typeKey: 'shared',
    icon: '/assets/img/20/share.svg'
  },
  [CollaborationMetrics.Reach]: {
    typeKey: 'reach',
    icon: '/assets/img/dashboard.svg'
  },
  [CollaborationMetrics.Likes]: {
    typeKey: 'likes',
    icon: '/assets/img/heart.svg'
  },
  [CollaborationMetrics.Impressions]: {
    typeKey: 'impressions',
    icon: '/assets/img/24/eye.svg'
  },
  [CollaborationMetrics.TimesSaved]: {
    typeKey: 'saved',
    icon: '/assets/img/24/bookmark.svg'
  },
  [CollaborationMetrics.Comments]: {
    typeKey: 'comments',
    icon: '/assets/img/chat.svg'
  },
  [CollaborationMetrics.TapsExit]: {
    typeKey: 'tapsExit',
    icon: '/assets/img/24/log-out.svg'
  },
  [CollaborationMetrics.TapsForward]: {
    typeKey: 'tapsForward',
    icon: '/assets/img/24/right-arrow.svg'
  },
  [CollaborationMetrics.TapsBack]: {
    typeKey: 'tapsBack',
    icon: '/assets/img/24/left-arrow.svg'
  }
};

export const DEFAULT_COLLABORATIONS_REQUESTS_BODY: IRequestBody = {
  filter: {
    brandIds: [],
    withStatus: [RequestStatus.New],
    sort: { date: 'DESC' }
  },
  options: {
    getRelated: true,
    includeCount: true
  }
};

export const DEFAULT_COLLABORATIONS_FILTERS_BODY: IRequestBody = {
  filter: {
    brandIds: [],
    withoutStatus: [RequestStatus.New],
    sort: { actionTakenDate: 'DESC' }
  },
  options: {
    getRelated: true,
    includeCount: true
  }
};

// Returns the current date in the next format ('YYYY-MM-dd hh:mm:ss');
export function getCurrentDateString() {
  const date = new Date();
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/* Returns the current date in the next format ('YYYY-MM-dd hh:mm:ss') by the default.
  If a date is passed, the formats can be:
  - format 1: 'YYYY-MM-dd hh:mm:ss'
  - format 2: 'dd/mm/YYYY'
 */
export function formatCurrentDateString(format: number, dateString: string) {
  const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;
  const match = regex.exec(dateString);
  if (match === null) return dateString;
  const [, year, month, day, hours, minutes, seconds] = match;
  switch (format) {
    case 1:
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    case 2:
      return `${day}/${month}/${year}`;
    default:
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}

// Returns the current date in the next format ('dd/MM/YYYY');
export function formatDate(date: string) {
  const res = /\d{4}-\d{2}-\d{2}/.exec(date);
  if (res !== null) {
    const [year, month, day] = res[0].split('-');
    return `${day}/${month}/${year}`;
  }
  return date;
}

export function getCategoriesString(categories: IOption[]) {
  let categoriesString = '';
  for (const { name, value } of categories) {
    const category = `${name} ${value}`;
    categoriesString = categoriesString.concat(`${category} | `);
  }
  return categoriesString;
}

export function getPublicationStats({
  comments,
  tapsExit,
  tapsForward,
  tapsBack,
  likes,
  saved,
  type,
  reach,
  shared,
  plays
}: IPublicationMapped) {
  switch (type) {
    case MediaTypes.StoryImage:
    case MediaTypes.StoryVideo:
      return [
        { stat: reach, img: '/24/dashboard', textKey: 'reach' },
        { stat: tapsExit, img: '/24/log-out', textKey: 'tapsExit' },
        { stat: tapsForward, img: '/24/right-arrow', textKey: 'tapsForward' },
        { stat: tapsBack, img: '/24/left-arrow', textKey: 'tapsBack' }
      ];
    case MediaTypes.FeedCarousel:
    case MediaTypes.FeedImage:
    case MediaTypes.FeedVideo:
      return [
        { stat: reach, img: '24/dashboard', textKey: 'reach' },
        { stat: likes, img: '24/heart', textKey: 'likes' },
        { stat: comments, img: '24/chat', textKey: 'comments' },
        { stat: saved, img: '24/bookmark', textKey: 'saved' }
      ];
    case MediaTypes.ReelVideo:
      return [
        { stat: likes, img: '24/heart', textKey: 'likes' },
        { stat: comments, img: '24/chat', textKey: 'comments' },
        { stat: plays, img: '20/play', textKey: 'plays' },
        { stat: saved, img: '24/bookmark', textKey: 'saved' },
        { stat: shared, img: '20/share', textKey: 'shared' }
      ];
    default:
      return [];
  }
}

/* Compare two arrays of strings, return true or falls */
export function arrayEquals(a: string[], b: string[]) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((value, index) => value === b[index])
  );
}

/**
 * Function that receives content, filename and content type, then creates a blob,
 * link, add blob url to the link and add download attribute to the link
 */
export function downloadBlob(
  content: string,
  filename: string,
  contentType: string
) {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);
  const linkToDownload = document.createElement('a');
  linkToDownload.href = url;
  linkToDownload.setAttribute('download', filename);
  linkToDownload.click();
}

export function getInfluencerUsernames(
  socialNetworksInfo: ISocialNetworksInfo[]
) {
  let influencerInstagramUsername = '';
  let influencerTikTokUsername = '';

  for (const network of socialNetworksInfo) {
    if (network.socialNetwork === SocialNetworks.Instagram) {
      influencerInstagramUsername = network.username;
    }
    if (network.socialNetwork === SocialNetworks.TikTok) {
      influencerTikTokUsername = network.username;
    }
  }

  return { influencerInstagramUsername, influencerTikTokUsername };
}

export function formatSelectedCollaborationsToCsvData(
  selectedCollaborations: Map<string, selectedCollaborationsStateValues>
) {
  let csvData: Array<string> = [
    'ID de colaboración; ID del producto; Nombre del producto; Tipo de colaboración; Nombre de influencer; Apellido(s) de influencer; Estado de la cuenta; Teléfono; Email; Dirección 1; Dirección 2; Zip; Ciudad; País; Instagram; TikTok'
  ];
  for (const [
    collaborationId,

    {
      acceptedCollaborationType,
      influencerInstagramUsername,
      influencerTikTokUsername,
      influencerProfileType,
      influencerFirstName,
      influencerLastName,
      influencerAddress,
      influencerPhone,
      influencerEmail,
      productInfo,
      productId,
      status
    }
  ] of selectedCollaborations) {
    const isAccountActive =
      influencerProfileType === InfluencerProfileTypes.Talent;
    const noProductInfo = Array.isArray(productInfo);
    const productSku = noProductInfo ? productId : productInfo?.id;

    if (
      status !== RequestStatus.Rejected &&
      status !== RequestStatus.Cancelled
    ) {
      csvData.push(
        `${collaborationId};${productSku};${
          productInfo?.title ?? 'No disponible'
        };${acceptedCollaborationType};${influencerFirstName ?? ''};${
          influencerLastName ?? ''
        };${isAccountActive ? 'Activo' : 'Bajo'};${
          influencerPhone ?? ''
        };${influencerEmail?.toLowerCase()};${
          influencerAddress?.address1 ?? 'No disponible'
        };${influencerAddress?.address2 ?? 'No disponible'};${
          influencerAddress?.zip ?? 'No disponible'
        };${influencerAddress?.city ?? 'No disponible'};${
          influencerAddress?.country ?? 'No disponible'
        };${influencerInstagramUsername};${influencerTikTokUsername}`
      );
    }
  }
  return csvData.join('\n');
}

/* Function used in collaborations' page */
export function getRequestBody(
  updatedCollaborationsRequestsBody: IRequestBody,
  router: NextRouter
) {
  const { isReady, query } = router;
  const storedRequestBody = getStorageValueIntoState(
    StorageKeys.CollaborationsFiltersBody,
    StorageTypes.SessionStorage
  );
  if (storedRequestBody) return storedRequestBody;

  if (isReady) {
    const { shippingStatus, requestStatus } = query;
    if (typeof shippingStatus !== 'undefined') {
      updatedCollaborationsRequestsBody.filter.withShippingStatus =
        typeof updatedCollaborationsRequestsBody.filter.withShippingStatus ===
        'undefined'
          ? [shippingStatus as ShippingStatuses]
          : [
              ...updatedCollaborationsRequestsBody.filter.withShippingStatus,
              shippingStatus as ShippingStatuses
            ];
    }
    if (typeof requestStatus !== 'undefined') {
      updatedCollaborationsRequestsBody.filter.withStatus =
        typeof updatedCollaborationsRequestsBody.filter.withStatus ===
        'undefined'
          ? [requestStatus as RequestStatus]
          : [
              ...updatedCollaborationsRequestsBody.filter.withStatus,
              requestStatus as RequestStatus
            ];
    }
  }
  return updatedCollaborationsRequestsBody;
}

/**
 * Function that receives an object and clones it with structuredClone method
 * or JSON.parse(JSON.stringify(obj)) if structuredClone is not supported
 */
export function treendStructuredClone<T>(objectToClone: T): T {
  let clonedObject: T;
  try {
    clonedObject = structuredClone(objectToClone);
  } catch {
    clonedObject = cloneDeep(objectToClone);
  }
  return clonedObject;
}

/**
 * Function that converts a Map object into a legible string such as:
 * Moda, Belleza y Deporte
 */
export function updateDropdownPlaceholder(
  value: Map<string, any>,
  placeholder: string
): string {
  if (value instanceof Map && value.size > 0) {
    const values = Array.from(value);
    if (values.length === 1) return `${values[0][0]}`;
    let finalText = '';
    for (const element of values) {
      finalText = `${finalText}${element[0]}, `;
    }
    return finalText.slice(0, -2).replace(/, (?=[A-záéíóúÁÉÍÓÚ]+$)/, ' y ');
  }
  return placeholder;
}

/**
 * Check if the given string contains at least one lowercase letter,
 * one uppercase letter and is at least 8 characters long
 */
export const validatePassword = (password: string) => {
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!ñÑ@#$%^&*()+=?;,./{}|":<>[\]\\' ~_-]{8,}$/;
  return re.test(password);
};

export const validateCIF = (cif: string) => {
  const re =
    /^(\d{8})([A-Za-z])$|^([ABCDEFGHJKLMNPQRSUVWabcdefghjklmnpqrsuvw]{1,3})(\d{8})$|^[XYZ]\d{7,8}[A-Z]$/;
  return re.test(cif);
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^ñ<>()[\]\\.,;:\s@"]+(\.[^ñ<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateSocialNetworkUsername = (name: string) => {
  const re = /^[a-zA-Z0-9._]{1,30}$/;
  return re.test(name);
};

export const isValidProductId = (id: string) => {
  const re = /^[a-zA-Z0-9_-]*$/;
  return re.test(id);
};

/**
 * Return the provinces from Spain
 */
export const SPAIN_REGIONS: [string, string][] = [
  ['Alava', 'Alava'],
  ['Albacete', 'Albacete'],
  ['Alicante', 'Alicante'],
  ['Almería', 'Almería'],
  ['Asturias', 'Asturias'],
  ['Avila', 'Avila'],
  ['Badajoz', 'Badajoz'],
  ['Barcelona', 'Barcelona'],
  ['Burgos', 'Burgos'],
  ['Cáceres', 'Cáceres'],
  ['Cádiz', 'Cádiz'],
  ['Cantabria', 'Cantabria'],
  ['Castellón', 'Castellón'],
  ['Ciudad Real', 'Ciudad Real'],
  ['Córdoba', 'Córdoba'],
  ['La Coruña', 'La Coruña'],
  ['Cuenca', 'Cuenca'],
  ['Gerona', 'Gerona'],
  ['Granada', 'Granada'],
  ['Guadalajara', 'Guadalajara'],
  ['Guipúzcoa', 'Guipúzcoa'],
  ['Huelva', 'Huelva'],
  ['Huesca', 'Huesca'],
  ['Islas Baleares', 'Islas Baleares'],
  ['Jaén', 'Jaén'],
  ['León', 'León'],
  ['Lérida', 'Lérida'],
  ['Lugo', 'Lugo'],
  ['Madrid', 'Madrid'],
  ['Málaga', 'Málaga'],
  ['Murcia', 'Murcia'],
  ['Navarra', 'Navarra'],
  ['Orense', 'Orense'],
  ['Palencia', 'Palencia'],
  ['Las Palmas', 'Las Palmas'],
  ['Pontevedra', 'Pontevedra'],
  ['La Rioja', 'La Rioja'],
  ['Salamanca', 'Salamanca'],
  ['Segovia', 'Segovia'],
  ['Sevilla', 'Sevilla'],
  ['Soria', 'Soria'],
  ['Tarragona', 'Tarragona'],
  ['Santa Cruz de Tenerife', 'Santa Cruz de Tenerife'],
  ['Teruel', 'Teruel'],
  ['Toledo', 'Toledo'],
  ['Valencia', 'Valencia'],
  ['Valladolid', 'Valladolid'],
  ['Vizcaya', 'Vizcaya'],
  ['Zamora', 'Zamora'],
  ['Zaragoza', 'Zaragoza']
];

export const DEFAULT_PRODUCT = {
  id: '',
  title: '',
  owner: '',
  description: '',
  brand: '',
  tags: '',
  productType: 'SIMPLE',
  productCategory: '',
  parent: '',
  quantity: 0,
  gtin: '0',
  ecommerceActive: true,
  options: [],
  imageInfo: [],
  lastUpdate: '',
  created: '',
  productUrl: '',
  product_url: '',
  shortDescription: ''
};

export const generateVariants = (options: [string, string[]][]) => {
  const result: Variant[] = [];

  const getCombinations = (depth: number, current: string[]) => {
    if (depth === options.length) {
      result.push({ value: current.join(' | '), sku: '', visible: true });
      return;
    }

    for (const element of options[depth][1]) {
      current.push(element);
      getCombinations(depth + 1, current);
      current.pop();
    }
  };

  getCombinations(0, []);
  return result;
};

export const generateVariantsFromProducts = (products: IProduct[]) => {
  const result: Variant[] = [];
  for (const product of products) {
    const value = (product.options as IOption[])
      .filter(({ name }) => name !== '')
      .map(({ value }) => `${value}`)
      .join(' | ');
    result.push({
      value,
      sku: product.id,
      visible: product.ecommerceActive
    });
  }
  return result;
};

export const buildProduct: (data: {
  productData: IProduct;
  hasVariants: boolean;
  variants: Variant[];
  optionsSelected: [string, string[]][];
}) => IProduct[] = ({
  productData,
  hasVariants,
  variants,
  optionsSelected
}) => {
  const checkedSalesPrice = Number(
    (productData.salesPrice as any).replace(',', '.')
  );
  productData.price = Number(productData.price.toString().replace(',', '.'));
  productData.salesPrice =
    typeof checkedSalesPrice === 'number' && checkedSalesPrice > 0
      ? checkedSalesPrice
      : productData.price;
  const generateSku = () => {
    const random = () => Math.floor(Math.random() * 0x10000).toString(16);
    return `${random()}${random()}-${random()}${random()}`;
  };
  const products: IProduct[] = [
    {
      ...productData,
      productType: hasVariants ? 'PARENT' : 'SIMPLE'
    },
    ...variants.map(({ sku, visible, value }) => {
      const values = value.split('|');
      const options = optionsSelected.map(([name], index) => ({
        name,
        value: values[index].trim()
      }));
      let id = `_${productData.id}`;
      if (hasVariants) id = sku !== '' ? sku : generateSku();
      return {
        ...productData,
        id,
        ecommerceActive: visible,
        options,
        parent: productData.id,
        productType: 'VARIANT',
        owner: productData.owner
      };
    })
  ];
  return products;
};

export const CATEGORIES = [
  [Categories.Fashion, Categories.Fashion],
  [Categories.Beauty, Categories.Beauty],
  [Categories.Home, Categories.Home],
  [Categories.Food, Categories.Food],
  [Categories.Fitness, Categories.Fitness],
  [Categories.Music, Categories.Music],
  [Categories.Family, Categories.Family],
  [Categories.Technics, Categories.Technics],
  [Categories.Animals, Categories.Animals],
  [Categories.Travel, Categories.Travel],
  [Categories.Culture, Categories.Culture],
  [Categories.Healthcare, Categories.Healthcare]
];

export const GENDERS = [
  [Genders.Undefined, Genders.Undefined],
  [Genders.Female, Genders.Female],
  [Genders.Male, Genders.Male]
];

export const swrBlockPublicRoutesFetch: Middleware = (useSWRNext: SWRHook) => {
  return (key, fetcher, config) => {
    const router = useRouter();
    const res = useSWRNext(key, fetcher, config);

    if (router.pathname === '/register' || router.pathname === '/login') {
      return null as unknown as SWRResponse<any>;
    }

    return res;
  };
};

export const getFileNameFromUrl = (url: string) => {
  const parts = url.split('/');
  const fileName = parts[parts.length - 1];
  return fileName.replace(/^[a-z0-9]+-/, '');
};

export const getDashboardStatusStyleProperties = (
  reviewStatus: boolean,
  brandApprovalStatus: BrandApprovalStatuses
) => {
  const rejected = {
    backgroundColor: 'bg-contrast-red-light',
    dotColor: 'bg-interaction-red',
    text: 'rejected',
    borderColor: 'border-contrast-red-medium'
  };
  const pendingReview = {
    backgroundColor: 'bg-primary-white',
    dotColor: 'bg-dark-grey',
    text: 'reviewPending',
    borderColor: 'border-medium-grey'
  };
  const inReview = {
    backgroundColor: 'bg-contrast-yellow-light',
    dotColor: 'bg-interaction-yellow',
    text: 'inReview',
    borderColor: 'border-contrast-yellow-medium'
  };
  const accepted = {
    backgroundColor: 'bg-contrast-green-light',
    dotColor: 'bg-interaction-green',
    text: 'published',
    borderColor: 'border-contrast-green-medium'
  };
  switch (brandApprovalStatus) {
    case BrandApprovalStatuses.Pending:
      return reviewStatus ? inReview : pendingReview;
    case BrandApprovalStatuses.Accepted:
      return accepted;
    case BrandApprovalStatuses.Rejected:
      return reviewStatus ? inReview : rejected;
    default:
      return pendingReview;
  }
};

export const isValidUrl = (urlString: string): boolean => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

/*  "DotPrefix" adds a dot prefix to a string type and "NestedKeys" recursively builds a union of all possible nested object keys as strings with dot prefixes. */
type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`;
export type NestedKeys<T> = (
  T extends object
    ? {
        [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<NestedKeys<T[K]>>}`;
      }[Exclude<keyof T, symbol>]
    : ''
) extends infer D
  ? Extract<D, string>
  : never;
