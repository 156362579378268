import { StorageKeys, StorageTypes } from '@/enums/enums';
import { getStorageValueIntoState } from '@/helpers/GeneralHelpers';
import type { ITokenInfo, IUserContext, IUserInfo } from '@/types/User';
import { createContext, useState, type ReactNode } from 'react';

const UserContext = createContext<IUserContext>({
  setTokenInfo: () => {},
  setUserInfo: () => {}
});

export function UserContextProvider(props: { children: ReactNode }) {
  const [tokenInfo, setTokenInfo] = useState<ITokenInfo | undefined>(() => {
    const tokenAvailable = getStorageValueIntoState(
      StorageKeys.TokenInfo,
      StorageTypes.SessionStorage
    );
    return tokenAvailable || undefined;
  });

  const [userInfo, setUserInfo] = useState<IUserInfo | undefined>(() => {
    const userInfoAvailable = getStorageValueIntoState(
      StorageKeys.TreendUser,
      StorageTypes.SessionStorage
    );
    return userInfoAvailable || undefined;
  });

  return (
    <UserContext.Provider
      value={{ tokenInfo, setTokenInfo, userInfo, setUserInfo }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContext;
