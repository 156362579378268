import type { Endpoints } from '@/enums/enums';
import type { Brand } from '@/types/Brand';
import type {
  IPublication,
  IRequest,
  IRequestBody
} from '@/types/Collaboration';
import type { IBillingInfo, ICompany } from '@/types/Company';
import type { IDashboardCollaborations } from '@/types/Dashboard';
import type { Catalog, IProduct, IProductsResponse } from '@/types/Product';
import type { ILoginForm, ITokenInfo, IUserInfo } from '@/types/User';
import useFetchWrapper from './useFetchWrapper';

interface ServerResponse {
  statusCode: number;
  data: {
    status: string;
  };
}

const useFetcher = () => {
  const { get, post, put, patch } = useFetchWrapper();
  const PRODUCTS_URL = process.env.NEXT_PUBLIC_PRODUCTS_URL!;
  const EVENTS_URL = process.env.NEXT_PUBLIC_EVENTS_API_URL!;

  const getCompany = async (url: Endpoints) =>
    await get<ICompany>(url, { version: 'v1' });

  const getUserInfoFetcher = async (url: Endpoints) =>
    await get<IUserInfo>(url, { version: 'v1' });

  const getDashboardData = async (url: Endpoints) =>
    await get<IDashboardCollaborations>(url, { version: 'v1' });

  const getPublicationMedia = async (url: string) =>
    await get<IPublication[]>(url, { version: 'v1' });

  const getBillingUrl = async (url: string) =>
    await get<IBillingInfo>(url, { version: 'v1' });

  const getBrand = async (url: string) =>
    await get<Brand>(url, { version: 'v1' });

  const getCatalog = async (url: Endpoints, owner: string) =>
    await get<Catalog>(url, {
      version: 'v1',
      params: { owner, onlyActive: 'false' },
      api: PRODUCTS_URL
    });

  const getProduct = async (url: string) => {
    const product = await get<{ product: IProduct; related: IProduct[] }>(url, {
      version: 'v1',
      api: PRODUCTS_URL,
      params: {
        withVariants: 'true'
      }
    });
    return [product.product, ...product.related] as IProduct[];
  };

  const getProductsFetcher = async (
    url: Endpoints,
    { products }: { products: { o: string; p: string }[] }
  ) =>
    await post<IProductsResponse>(url, products, {
      version: 'v1',
      api: PRODUCTS_URL
    });

  const getTokenInfoFetcher = async (
    url: Endpoints,
    { loginInfo }: { loginInfo: ILoginForm }
  ) => await post<ITokenInfo>(url, loginInfo, { version: 'v1' });

  const getCollaborationsFetcher = async (
    url: string,
    { requestBody }: { requestBody: IRequestBody }
  ) =>
    await post<IRequest>(url, requestBody, {
      version: 'v4'
    });

  const updateProductParent = async (
    url: string,
    { products }: { products: IProduct[] }
  ) =>
    await put<IRequest>(url, products, {
      version: 'v1',
      api: PRODUCTS_URL
    });

  const sendEmailToEvents = async (url: string, { email }: { email: string }) =>
    await post(url, { email }, { version: 'v2', api: EVENTS_URL });

  const impersonate = async (url: string, email: string) =>
    await post<ITokenInfo>(url, { email }, { version: 'v1' });

  const toggleBrandDisabled = async (url: string) =>
    await post(url, {}, { version: 'v1' });

  const sendRecoveryEmail = async (url: string, email: string) =>
    await post<ServerResponse>(url, { email }, { version: 'v1' });

  const sendNewPassword = async (
    url: string,
    password: string,
    token: string
  ) => await post<ServerResponse>(url, { password, token }, { version: 'v1' });

  const updateBrand = async (url: string, brand: Partial<Brand>) =>
    await patch<Brand>(url, brand, { version: 'v1' });

  return {
    getCompany,
    getUserInfoFetcher,
    getDashboardData,
    getPublicationMedia,
    getProductsFetcher,
    getCatalog,
    getProduct,
    getTokenInfoFetcher,
    getCollaborationsFetcher,
    getBillingUrl,
    updateProductParent,
    getBrand,
    sendEmailToEvents,
    impersonate,
    toggleBrandDisabled,
    sendRecoveryEmail,
    sendNewPassword,
    updateBrand
  };
};

export default useFetcher;
