import UserContext from '@/contexts/UserContext';
import { ContentType, Endpoints, StorageKeys } from '@/enums/enums';
import { setSentryError } from '@/helpers/SentryFunctions';
import type { ITokenInfo } from '@/types/User';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useContext } from 'react';

type Versions = 'v1' | 'v2' | 'v3' | 'v4';

interface FetchOptions {
  version: Versions;
  api?: string;
  body?: any;
  contentType?: ContentType;
  params?: Record<string, string>;
  token?: string;
}

export default function useFetchWrapper() {
  const API_URL = process.env.NEXT_PUBLIC_API_URLBASE!;
  const { setTokenInfo, setUserInfo } = useContext(UserContext);
  const router = useRouter();

  const get = async <T>(path: string, options: FetchOptions): Promise<T> => {
    const url = buildUrl(path, options);
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: authHeader()
    };
    return await fetch(url, requestOptions)
      .then(handleResponse)
      .catch((error) => {
        setSentryError({
          data: error,
          message: 'GET error on fetch from url: ' + url,
          tagSection: 'useFetchWrapper'
        });
        throw error;
      });
  };

  const post = <T>(
    path: string,
    body: any,
    options: FetchOptions
  ): Promise<T> => {
    const url = buildUrl(path, options);
    const { contentType } = options;
    const headers = authHeader();
    if (contentType !== ContentType.multipartFormData) {
      headers.set('Content-Type', ContentType.applicationJson);
    }
    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      body:
        !contentType || contentType === ContentType.applicationJson
          ? JSON.stringify(body)
          : body
    };
    return fetch(url, requestOptions)
      .then(handleResponse)
      .catch((error) => {
        setSentryError({
          data: error,
          message: 'POST error on fetch from url: ' + url,
          tagSection: 'useFetchWrapper'
        });
        throw error;
      });
  };

  const patch = <T>(
    path: string,
    body: any,
    options: FetchOptions
  ): Promise<T> => {
    const url = buildUrl(path, options);
    const headers = authHeader();
    headers.set('Content-Type', ContentType.applicationJson);
    const requestOptions: RequestInit = {
      method: 'PATCH',
      headers,
      body: JSON.stringify(body)
    };
    return fetch(url, requestOptions)
      .then(handleResponse)
      .catch((error) => {
        setSentryError({
          data: error,
          message: 'PATCH error on fetch from url: ' + url,
          tagSection: 'useFetchWrapper'
        });
        throw error;
      });
  };

  const put = <T>(
    path: string,
    body: any,
    options: FetchOptions
  ): Promise<T> => {
    const url = buildUrl(path, options);
    const headers = authHeader();
    headers.set('Content-Type', ContentType.applicationJson);
    const requestOptions: RequestInit = {
      method: 'PUT',
      headers,
      body: JSON.stringify(body)
    };
    return fetch(url, requestOptions)
      .then(handleResponse)
      .catch((error) => {
        setSentryError({
          data: error,
          message: 'PUT error on fetch from url: ' + url,
          tagSection: 'useFetchWrapper'
        });
        throw error;
      });
  };

  const _delete = <T>(path: string, options: FetchOptions): Promise<T> => {
    const headers = authHeader();
    headers.set('Content-Type', ContentType.applicationJson);
    const url = buildUrl(path, options);
    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers
    };
    return fetch(url, requestOptions)
      .then(handleResponse)
      .catch((error) => {
        setSentryError({
          data: error,
          message: 'DELETE error on fetch from url: ' + url,
          tagSection: 'useFetchWrapper'
        });
        throw error;
      });
  };

  const handleResponse = (response: Response) => {
    return response.text().then(async (text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          if (typeof window !== 'undefined') {
            window.sessionStorage.clear();
          }
          setTokenInfo(undefined);
          setUserInfo(undefined);
          Sentry.setUser(null);
          router.push(Endpoints.Login);
        }
        const error: Error & { info: object; status: number } = {
          ...new Error('An error occurred while fetching the data.'),
          info: data,
          status: response.status
        };
        throw error;
      }
      return data.data;
    });
  };

  const authHeader: () => Headers = () => {
    const tokenInfo: ITokenInfo | null =
      typeof window !== 'undefined' && 'tokenInfo' in window.sessionStorage
        ? JSON.parse(
            window.sessionStorage.getItem(StorageKeys.TokenInfo) as string
          )
        : null;
    const headers = new Headers();
    if (tokenInfo) {
      headers.set('Authorization', `${tokenInfo.token}`);
    }
    return headers;
  };

  const buildUrl = (path: string, options: FetchOptions) => {
    const { version, api, params } = options;
    let url = (api ?? API_URL).replace('_VERSION_', version) + path;
    if (params) {
      url += `?${new URLSearchParams(params).toString()}`;
    }
    return url;
  };

  return {
    get,
    post,
    put,
    patch,
    _delete
  };
}
