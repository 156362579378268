import type { RequestBodyType } from '@/components/ModalChangeShippingStatus/ModalChangeShippingStatus.types';
import { RequestActionTypes, StorageKeys, StorageTypes } from '@/enums/enums';
import type {
  ICollaboration,
  ICollaborationsInfo
} from '@/types/Collaboration';
import type { IProductsResponse } from '@/types/Product';
import { getStorageValueIntoState, setStorageValue } from './GeneralHelpers';

export type RequestState = {
  pageCount: number;
  currentPage: number;
  showContent: boolean;
  collaborationsInfo: ICollaborationsInfo | undefined;
  productIds: { o: string; p: string }[];
  products: IProductsResponse | undefined;
  reloadPage: Object;
};

export const INITIAL_REQUESTS_STATE: RequestState = {
  showContent: false,
  collaborationsInfo: undefined,
  pageCount: 0,
  currentPage: 0,
  productIds: [],
  products: undefined,
  reloadPage: {}
};

export type RequestAction = {
  type: RequestActionTypes;
  currentPage?: number;
  collaborationsInfo?: ICollaborationsInfo;
  newProductIds?: {
    o: string;
    p: string;
  }[];
  products?: IProductsResponse;
  collaborationsToUpdate?: RequestBodyType;
  collaborationsIds?: string[];
};

function getRestartState(): RequestState {
  return { ...INITIAL_REQUESTS_STATE };
}

export function requestsReducer(
  state: RequestState,
  action: RequestAction
): RequestState {
  const NUMBER_OF_ITEMS = 5;
  if (typeof action === 'undefined') return state;
  let newState = { ...state };
  const lastPage = newState.pageCount === newState.currentPage + 1;
  switch (action.type) {
    case RequestActionTypes.Restart:
      newState = getRestartState();
      break;
    case RequestActionTypes.HideContent:
      newState.showContent = false;
      break;
    case RequestActionTypes.SetOffset:
      newState.currentPage = action.currentPage || 0;
      break;
    case RequestActionTypes.SetCollaborationsAndProductsIds:
      newState.collaborationsInfo = action.collaborationsInfo;
      newState.productIds = action.newProductIds || [];
      break;
    case RequestActionTypes.SetProducts:
      newState.products = action.products;
      break;
    case RequestActionTypes.EndProcess:
      const count = action.collaborationsInfo?.count ?? newState.currentPage;
      const pageCount = Math.ceil(count / NUMBER_OF_ITEMS);
      newState = {
        ...newState,
        pageCount,
        collaborationsInfo: action.collaborationsInfo,
        showContent: true
      };
      break;
    case RequestActionTypes.ShowContent:
      newState.showContent = true;
      break;
    case RequestActionTypes.AcceptOrRejectRequest:
      const noMoreRequests =
        (newState.collaborationsInfo?.collaborations as ICollaboration[])
          .length -
          1 ===
        0;
      if (noMoreRequests && lastPage) {
        newState.currentPage--;
        newState.pageCount--;
      } else {
        newState.reloadPage = {};
      }
      break;
    case RequestActionTypes.RejectInBulk:
      const { collaborationsIds } = action;
      const collaborationsIdsLength = (collaborationsIds as string[]).length;
      const collaborationsLength = (
        newState.collaborationsInfo?.collaborations as ICollaboration[]
      ).length;
      if ((collaborationsIds as string[]).length > 6) {
        newState = getRestartState();
      } else if (lastPage && collaborationsIdsLength === collaborationsLength) {
        newState.currentPage--;
        newState.pageCount--;
      } else {
        newState.reloadPage = {};
      }
      break;
    case RequestActionTypes.UpdateShippingStatus:
      const { collaborationsToUpdate } = action;
      const collaborations = (
        newState.collaborationsInfo as ICollaborationsInfo
      ).collaborations.map((collaboration) => {
        const updateInfo = (collaborationsToUpdate as RequestBodyType).find(
          (collaborationToUpdate) =>
            collaborationToUpdate.collaborationRequestId ===
            collaboration.collaborationRequestId
        );
        if (typeof updateInfo !== 'undefined') {
          collaboration.shippingStatus = updateInfo.shippingStatus;
        }
        return collaboration;
      });
      newState.collaborationsInfo = {
        ...(newState.collaborationsInfo as ICollaborationsInfo),
        collaborations
      };
      break;
    default:
      break;
  }
  setStorageValue(
    StorageKeys.RequestsState,
    newState,
    StorageTypes.SessionStorage
  );
  return newState;
}

export function initRequestsState(initialState: RequestState) {
  const savedState = getStorageValueIntoState(
    StorageKeys.RequestsState,
    StorageTypes.SessionStorage
  );
  if (savedState) return savedState;
  return initialState;
}
