module.exports = {
  i18n: {
    defaultLocale: 'es',
    locales: ['es', 'en']
  },
  returnNull: false,
  reloadOnPrerender: process.env.ENV === 'development',
  serializeConfig: false,
  ns: [
    'common',
    'collaborationsPage',
    'collaboratorStatistics',
    'idCollaborationPage',
    'loginPage',
    'registerPage',
    'requestsPage',
    'dashboardPage',
    'settingsPage',
    'countries',
    'addProduct',
    'catalog',
    'branding',
    'mobileErrorPage',
    'resetPassword',
    'newPassword',
    'newPasswordFinal'
  ],
  async redirects() {
    return [
      {
        source: '/with-locale',
        destination: '/en',
        permanent: false,
        has: [
          {
            type: 'cookie',
            key: 'NEXT_LOCALE',
            value: 'en'
          }
        ]
      },
      {
        source: '/with-locale',
        destination: '/',
        permanent: false,
        has: [
          {
            type: 'cookie',
            key: 'NEXT_LOCALE',
            value: 'es'
          }
        ]
      }
    ];
  }
};
