import { Routes } from '@/enums/enums';
import useUser from '@/hooks/useUser';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export default function RouteGuard({ children }: any) {
  const router = useRouter();
  const { isLogged } = useUser();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    authCheck(router.asPath);
    router.events.on('routeChangeComplete', authCheck);

    return () => {
      router.events.off('routeChangeComplete', authCheck);
    };
  });

  const authCheck = (url: string) => {
    const loginURL = Routes.Login;
    const registerURL = Routes.Register;
    const resetPasswordURL = Routes.ResetPassword;
    const newPasswordURL = Routes.NewPassword;
    const newPasswordFinalURL = Routes.NewPasswordFinal;
    // TODO: use another data structure to improve route checking
    const publicPaths = [
      loginURL,
      `/en${loginURL}`,
      registerURL,
      `/en${registerURL}`,
      resetPasswordURL,
      `/en${resetPasswordURL}`,
      newPasswordURL,
      `/en${newPasswordURL}`,
      newPasswordFinalURL,
      `/en${newPasswordFinalURL}`
    ];
    const path = url.split('?')[0];
    if (!isLogged && !publicPaths.includes(path)) {
      setAuthorized(false);
      router.replace(loginURL);
    } else {
      setAuthorized(true);
      if (router.route === '/') router.push('/panel/requests?page=1');
    }
  };

  return authorized && children;
}
