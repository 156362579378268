import UserContext from '@/contexts/UserContext';
import { BrandApprovalStatuses, Endpoints } from '@/enums/enums';
import { swrBlockPublicRoutesFetch } from '@/helpers/GeneralHelpers';
import { setSentryError } from '@/helpers/SentryFunctions';
import useFetcher from '@/hooks/useFetcher';
import type { BrandRestrictions } from '@/types/Brand';
import type { BrandingFormFields } from '@/types/BrandingReducer';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useState } from 'react';
import useSWR from 'swr';

export interface IBrandContext {
  fields: BrandingFormFields;
  brandUrl: string;
  storeName: string;
  inReview: boolean;
  catalogReady: boolean;
  isBrandingFormComplete: boolean;
  brandApprovalStatus: BrandApprovalStatuses;
  restrictions: BrandRestrictions;
  setBranding: Dispatch<SetStateAction<IBrandContext>>;
  catalogDisabled: boolean;
  instagramHandle?: string;
  tiktokHandle?: string;
}

export const INITIAL_BRAND_CONTEXT: IBrandContext = {
  fields: {
    logoUrl: '',
    bannerUrl: '',
    description: '',
    briefingPoints: ['']
  },
  storeName: '',
  inReview: false,
  brandApprovalStatus: BrandApprovalStatuses.Pending,
  isBrandingFormComplete: false,
  catalogReady: false,
  catalogDisabled: false,
  brandUrl: '',
  restrictions: {
    availableCollaborationRequests: 0,
    maxProducts: 0
  },
  setBranding: () => {},
  instagramHandle: '',
  tiktokHandle: ''
};

const BrandContext = createContext<IBrandContext>({
  ...INITIAL_BRAND_CONTEXT
});

export function BrandContextProvider(props: { children: ReactNode }) {
  const { getBrand } = useFetcher();
  const { userInfo } = useContext(UserContext);
  const [triggerBrandingUpdate, setTriggerBrandingUpdate] = useState(false);
  const { companyId, brandIds } = userInfo || {
    companyId: '',
    brandIds: []
  };
  const [brandingState, setBranding] = useState<IBrandContext>(
    INITIAL_BRAND_CONTEXT
  );
  const brandUrl = `${Endpoints.Company}/${companyId}${Endpoints.Brand}/${brandIds[0]}`;

  useSWR(
    (companyId && brandIds[0]) || triggerBrandingUpdate ? brandUrl : null,
    getBrand,
    {
      use: [swrBlockPublicRoutesFetch],
      onSuccess: (data) => {
        setBranding({
          fields: {
            logoUrl: data.logoUrl,
            bannerUrl: data.bannerUrl,
            description: data.description,
            briefingPoints: data.briefingPoints
          },
          restrictions: data.restrictions,
          storeName: data.storeName || '',
          inReview: data.inReview,
          brandApprovalStatus: data.brandApprovalStatus,
          isBrandingFormComplete: false,
          catalogReady: data.catalogReady,
          catalogDisabled: data.catalogDisabled,
          brandUrl,
          setBranding: setBranding,
          instagramHandle: data.instagramHandle,
          tiktokHandle: data.tiktokHandle
        });
        setTriggerBrandingUpdate(false);
      },
      onError: (error) => {
        setSentryError({
          data: error,
          message: 'Error fetching brand data',
          tagSection: 'Branding Page'
        });
      },
      revalidateOnFocus: false,
      keepPreviousData: true
    }
  );

  const isBrandingFormComplete = (): boolean =>
    !!brandingState.fields.logoUrl &&
    !!brandingState.fields.bannerUrl &&
    !!brandingState.fields.description &&
    brandingState.fields.briefingPoints.length > 0 &&
    brandingState.fields.briefingPoints[0] !== '';

  return (
    <BrandContext.Provider
      value={{
        ...brandingState,
        isBrandingFormComplete: isBrandingFormComplete(),
        setBranding,
        brandUrl
      }}
    >
      {props.children}
    </BrandContext.Provider>
  );
}

export default BrandContext;
