import RequestsContext from '@/contexts/CollaborationRequestsContext';
import UserContext from '@/contexts/UserContext';
import { Endpoints, StorageKeys, StorageTypes } from '@/enums/enums';
import { setStorageValue } from '@/helpers/GeneralHelpers';
import { setSentryError } from '@/helpers/SentryFunctions';
import useFetcher from '@/hooks/useFetcher';
import type { ILoginForm, IUserInfo } from '@/types/User';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useCallback, useContext, useState } from 'react';
import useSWR, { preload, useSWRConfig } from 'swr';

export default function useUser() {
  const router = useRouter();
  const { mutate } = useSWRConfig();
  const { collaborationSelected } = useContext(RequestsContext);
  const [state, setState] = useState({ loading: false, error: false });
  const { tokenInfo, setTokenInfo, setUserInfo, userInfo } =
    useContext(UserContext);
  const { getUserInfoFetcher, getDashboardData, getTokenInfoFetcher } =
    useFetcher();

  const setLoginError = useCallback(
    (error: boolean) => {
      setState((state) => ({
        ...state,
        error
      }));
    },
    [setState]
  );

  useSWR<IUserInfo>(
    tokenInfo ? `${Endpoints.User}/${tokenInfo.userId}` : null,
    getUserInfoFetcher,
    {
      onError: (e) => {
        setLoginError(true);
        console.error(e);
      },
      onSuccess: (responseUserInfo) => {
        setLoginError(false);
        setStorageValue(
          StorageKeys.TreendUser,
          responseUserInfo,
          StorageTypes.SessionStorage
        );
        preload(Endpoints.Dashboard, getDashboardData);
        setUserInfo(responseUserInfo);
        Sentry.setUser({
          email: responseUserInfo.email,
          id: responseUserInfo.userId
        });
      },
      revalidateIfStale: !userInfo, // to prevent page reload when toggle UserMenu
      revalidateOnFocus: typeof collaborationSelected !== 'undefined' // if true(by default), it will close and open modals and make imposible to attach files
    }
  );

  const login = useCallback<(loginInfo: ILoginForm) => Promise<void>>(
    async (loginInfo: ILoginForm) => {
      setState({ loading: true, error: false });
      const url = Endpoints.Access;
      try {
        const loginRes = await mutate(
          url,
          getTokenInfoFetcher(url, { loginInfo: loginInfo })
        );
        const isLoginResValid = typeof loginRes !== 'undefined';
        if (isLoginResValid)
          setStorageValue(
            StorageKeys.TokenInfo,
            loginRes,
            StorageTypes.SessionStorage
          );
        setState({
          loading: false,
          error: !isLoginResValid
        });
        setTokenInfo(loginRes);
        if (!isLoginResValid) {
          throw new Error('Login failed');
        }
      } catch (e) {
        setSentryError({
          data: e,
          message: 'Login error of user: ' + loginInfo.email,
          tagSection: 'useUser'
        });
        setState({
          loading: false,
          error: true
        });
        console.error(e);
      }
    },
    [setTokenInfo, setState, mutate, getTokenInfoFetcher]
  );

  const clearCache = useCallback<() => void>(
    () => mutate(() => true, undefined, { revalidate: false }),
    [mutate]
  );

  const logout = useCallback<() => void>(() => {
    const keysToDelete = [
      StorageKeys.TokenInfo,
      StorageKeys.TreendUser,
      StorageKeys.RequestsState,
      StorageKeys.RequestsFiltersBody,
      StorageKeys.CollaborationsFiltersBody
    ];
    for (const keyToDelete of keysToDelete) {
      window.sessionStorage.removeItem(keyToDelete);
    }
    setTokenInfo(undefined);
    setUserInfo(undefined);
    Sentry.setUser(null);
    clearCache();
    router.push(Endpoints.Login);
  }, [setTokenInfo, setUserInfo, router, clearCache]);

  return {
    isLogged: Boolean(tokenInfo),
    isLoginLoading: state.loading,
    hasLoginError: state.error,
    setLoginError,
    login,
    logout
  };
}
